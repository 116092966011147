import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css'
import Logo from '../../images/lifeguide-logo.png';
import FooterLogo from '../../images/logo-footer.png';
import "@fontsource/inter"
import "../../styles/main.scss"
import markerIcon from '../../images/marker.png'
import phoneIcon from '../../images/phone.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Mailchimp from '../resource/mailchimp'
import Navbar from 'react-bootstrap/Navbar'
import twitter from '../../images/twt.svg'
import fb from '../../images/fb.svg'
import ig from '../../images/ig.svg'
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Link } from 'gatsby';
import parse from 'html-react-parser';

// styles
const pageStyles = {
  color: "#232129",
}

const logoStyle = {
  maxWidth: '143px'
}

const redirectHome = () => {
  window.location.href = `${process.env.HOME_URL_PATH}`
}

const redirectLogin = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_in`
}

const redirectSignup = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_up`
}

const redirectSubscribe = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/subscription`
}

const redirectSocialMedia = (sc) => {
  window.location.href = parse(sc)[1].props.children.props.href
}

const formActionUrl = process.env.MAILCHIMP_FORM

const allPostWp1 = graphql`
  query GETMAINMENU1 {
    allWpCategory {
      nodes {
        name
        posts {
          nodes {
            id
            title
            excerpt
            content
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    wpCategory(name: {eq: "Footer"}) {
      id
      posts {
        nodes {
          content
        }
      }
    }
  }
`

const PrivacyPolicyPage = () => {
  return (
    <main style={pageStyles} id="root">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lifeguide Consultancy</title>
        </Helmet>
      </div>
      <section className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <section className="section-pages first-section" id='head'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Navbar collapseOnSelect expand="lg">
                      <Navbar.Brand href="#home" onClick={() => redirectHome()}>
                        <img src={Logo} style={logoStyle} className='w-100' />
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav mx-auto">
                          <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#about-us">About Us</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#" onClick={() => redirectSubscribe()}>Subscribe</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#how-it-works">How it works</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#faq">FAQ</a>
                          </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                          <button className="btn btn-ghost mr-3" onClick={() => redirectLogin()}>Sign in</button>
                          <button className="btn btn-signup my-2 my-sm-0" onClick={() => redirectSignup()}>Sign up</button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h1 className="text-center custom-margin-bottom">Privacy Policy</h1>
                  </div>
                </div>
              </div>
            </section>
            <StaticQuery
              query={allPostWp1}
              render={(data) => {
                if (data.allWpCategory) {
                  const posts = data.allWpCategory.nodes
                  const footer = data.wpCategory.posts
                  return (
                    <div>
                      <section className="section-content mb-4">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                {posts && posts.map((post) => {
                                  if (post.name === "Privacy Policy") {
                                    return post.posts.nodes.map((article) => {
                                      return (
                                        <div className="col-12 mt-4 mb-4">
                                          <div dangerouslySetInnerHTML={{ __html: article.content }} />
                                        </div>
                                      )
                                    })
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <footer className="page-footer pt-0">
                        <div className="bg-footer-dark">
                          <div className="container">
                            <div className="row p-0">
                              <div className="col-12 col-lg-4 mb-4">
                                <h6 className="text-uppercase font-weight-bold mb-3">
                                  <img src={FooterLogo} style={logoStyle} className='w-100' />
                                </h6>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={phoneIcon} alt="phone" />
                                  </div>
                                  <div className="col-10">
                                    <p className="text-gray">
                                      <div dangerouslySetInnerHTML={{ __html: footer.nodes[3].content }} />
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={markerIcon} alt="marker" />
                                  </div>
                                  <div className="col-10">
                                    <p className="text-gray">
                                      <div dangerouslySetInnerHTML={{ __html: footer.nodes[4].content }} />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-lg-2 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Company</h6>
                                <p>
                                  <a href="/#head">Home</a>
                                </p>
                                <p>
                                  <a href="/#about-us">About Us</a>
                                </p>
                                <p>
                                  <a href="" onClick={() => redirectSubscribe()}>Subscribe</a>
                                </p>
                                <p>
                                  <Link to="/resource">Resources</Link>
                                </p>
                                <p>
                                  <Link to="/privacyPolicy">Privacy Policy</Link>
                                </p>
                              </div>
                              <div className="col-6 col-lg-2 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Help</h6>
                                <p>
                                  <a href="/#how-it-works">How it works</a>
                                </p>
                                <p>
                                  <a href="/#faq">FAQ</a>
                                </p>
                              </div>
                              <div className="col-12 col-lg-4 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Subscribe to our newsletter</h6>
                                <p className="text-white">The latest news, articles, and resources, sent to your inbox weekly.</p>
                                <Mailchimp
                                  action={formActionUrl}
                                  fields={[
                                    {
                                      name: 'EMAIL',
                                      placeholder: 'Email Address',
                                      type: 'email',
                                      required: true
                                    }
                                  ]}
                                />
                              </div>
                            </div>
          
                            <hr className="bg-gray" />
                            <div className="row pt-2 pb-4">
                              <div className="col-6">
                                <p className="text-gray">&copy; 2021 Lifeguideconsultancy. All rights reserved.</p>
                              </div>
                              <div className="col-6 text-right footer-sns">
                                <img src={fb} onClick={() => redirectSocialMedia(footer.nodes[2].content)}/>
                                <img src={ig} onClick={() => redirectSocialMedia(footer.nodes[1].content)}/>
                                <img src={twitter} onClick={() => redirectSocialMedia(footer.nodes[0].content)}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </div>
                  )
                }
              }}
            />
          </div>
        </div>
      </section>
    </main>
  )
}

export default PrivacyPolicyPage;
